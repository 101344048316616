import { FormControl } from "@angular/forms";

export function notOperator(c: FormControl) {
  const error = {
    notOperator: {
      valid: false,
      message: "Fehler: Not-Operator"
    }
  };

  const leadingOperandRgx = new RegExp("[a-z01\)]");
  const followingOperandRgx = new RegExp("[a-z01\(¬!]");

  for (let i = 0; i < c.value.length; i++) {
    if (c.value[i] === "!" || c.value[i] === "¬") {
      // leading symbol has to be an operator
      if (i >= 1) {
        const previousValue = c.value[i - 1];
        if (leadingOperandRgx.test(previousValue)) {
          error.notOperator.message = "Fehler: Vor Not-Operator muss ein Operator stehen [Pos.: " + i + "]";
          return error;
        }
      }

      const nextValue = c.value[i + 1];
      if (!nextValue || !followingOperandRgx.test(nextValue)) {
        error.notOperator.message = "Fehler: Hinter Not-Operator muss ein Operand stehen [Pos.: " + i + "]";
        return error;
      }

    }
  }

  return null;
}
