import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TruthtableProvider {
  public calculatorInput = "";
  public calculatorInputChange: EventEmitter<string> = new EventEmitter();

  public originalExpression: string;
  public prefixExpression: string[] = [];
  public prefixBinaryExpressions: {
    binary: string;
    prefixExpression: string;
    flagTruthOperand: boolean;
    flagFalseOperand: boolean;
  }[] = [];

  public subExpression: string[] = [];

  public operands: Set<string> = new Set([]);
  public filteredOperands: Set<string> = new Set([]); // without 0 and 1

  public operators: Set<string> = new Set([]);
  public steps: string[] = [];

  public header: string[] = [];

  public resultMap: string[][] = [];

  public table: string[][] = [];

  public errors: string[] = [];

  public showTableInfo1 = true;
  public showTableInfo2 = true;
  public showTreeInfo = true;

  public ready = true;

  constructor() {}
}
