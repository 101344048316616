import { FormControl } from "@angular/forms";

export function parenthesesMatch(c: FormControl) {
  const error = {
    parenthesesMatch: {
      valid: false,
      message: "Fehler: Klammersetzung"
    }
  };

  let openParentheses = 0;
  let closeParentheses = 0;
  for (let i = 0; i < c.value.length; i++) {
    if (c.value[i] === "(") {
      openParentheses++;
    } else if (c.value[i] === ")") {
      closeParentheses++;
    }

    if (c.value[i] === "(" && c.value[i + 1] === ")") {
      error.parenthesesMatch.message = "Fehler: Leere Klammern [Pos.: " + (i) + " - " + (i + 1) + "]";
      return error;
    }

    if (openParentheses < closeParentheses) {
      error.parenthesesMatch.message = "Fehler: Fehlende Klammern";
      return error;
    }
  }

  return openParentheses === closeParentheses ? null : error;
}
