import { FormControl } from "@angular/forms";

export function operandPosition(c: FormControl) {
  const error = {
    operandPosition: {
      valid: false,
      message: "Unbekannter Fehler: Operanden"
    }
  };

  const rgx = new RegExp("^[a-z0-1]*$");

  for (let i = 0; i < c.value.length - 1; i++) {
    if (rgx.test(c.value[i]) && rgx.test(c.value[i + 1])) {
      error.operandPosition.message =
      "Fehler: Operand folgt Operand [ " +
      c.value[i] +
      " (Pos.: " +
      i +
      ")]";
      return error;
    }
  }

  return true ? null : error;
}
