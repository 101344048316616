import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AllowedOperators,
  OperatorsToSymbols,
  BooleanOperator
} from "../enums/boolean-operator.enum";
import { TruthtableProvider } from "../providers/truthtable.provider";

@Injectable({
  providedIn: "root"
})
export class ExpressionService {
  constructor(private TruthtablePRV: TruthtableProvider) {}

  public and(a: boolean, b: boolean): boolean {
    return a && b;
  }

  public equivalence(a: boolean, b: boolean): boolean {
    return a === b;
  }

  public implies(a: boolean, b: boolean): boolean {
    return !a || b;
  }

  public nand(a: boolean, b: boolean): boolean {
    return !(a && b);
  }

  public nor(a: boolean, b: boolean): boolean {
    return !a && !b;
  }

  public not(a: boolean): boolean {
    return !a;
  }

  public or(a: boolean, b: boolean): boolean {
    return a || b;
  }

  public xor(a: boolean, b: boolean): boolean {
    return (a && !b) || (!a && b);
  }

  public variablesToBooleanValues(
    variables: string[]
  ): Observable<{ var: boolean[] }[]> {
    return new Observable();
  }

  public parsePrefixExpression(expression: string): Observable<string[]> {
    return new Observable<string[]>(observer => {
      if (!!expression) {
        Object.keys(OperatorsToSymbols).forEach(key => {
          const rgx = new RegExp(key, "g");

          expression = expression.replace(rgx, OperatorsToSymbols[key]);
        });

        expression = expression.replace(/ /g, ""); // get rid of empty spaces

        this.TruthtablePRV.operands = new Set([]);

        const stack: string[] = [];
        const result: string[] = [];

        for (let index = expression.length - 1; index >= 0; index--) {
          const element = expression[index];

          if (element.match(/[0-1A-Za-z]/gi)) {
            result.push(element);
            this.TruthtablePRV.operands.add(element);
            this.TruthtablePRV.operands = new Set(
              [...this.TruthtablePRV.operands].sort()
            );
          } else if (element === ")" || element === "!") {
            stack.push(element);
          } else if (Object.keys(AllowedOperators).includes(element)) {
            let lastElementFromStack = stack[stack.length - 1];
            let lastElementFromStackWeight =
              AllowedOperators[lastElementFromStack];
            const elementWeight = AllowedOperators[element];

            while (
              stack.length > 0 &&
              lastElementFromStackWeight >= elementWeight
            ) {
              stack.pop();
              result.push(lastElementFromStack);

              lastElementFromStack = stack[stack.length - 1];
              lastElementFromStackWeight =
                AllowedOperators[lastElementFromStack];
            }

            stack.push(element);
          } else if (element === "(") {
            let lastElementFromStack = stack[stack.length - 1];

            while (stack.length > 0 && lastElementFromStack !== ")") {
              stack.pop();
              result.push(lastElementFromStack);

              lastElementFromStack = stack[stack.length - 1];
            }

            if (lastElementFromStack === ")") {
              stack.pop();
            }
          }
        }

        const parsed = result.concat(stack.reverse()).reverse();
        observer.next(parsed);
      }
      observer.complete();
    });
  }

  public replacePrefixOperandsWithBinaries(
    expression: string,
    operands: Set<string>
  ): Observable<{
    binary: string;
    prefixExpression: string;
    flagTruthOperand: boolean;
    flagFalseOperand: boolean;
  }> {
    return new Observable<{
      binary: string;
      prefixExpression: string;
      flagTruthOperand: boolean;
      flagFalseOperand: boolean;
    }>(observer => {
      this.TruthtablePRV.filteredOperands = operands;

      const lengthOfBinary = 2 ** operands.size;

      let binary = [];
      for (let i = 0; i < lengthOfBinary; i++) {
        binary = i
          .toString(2)
          .padStart(operands.size, "0")
          .split("");

        let counter = 0;
        let tempExpression = expression;
        operands.forEach(op => {
          const rgx = new RegExp(op, "g");

          if (isNaN(Number(op))) {
            tempExpression = tempExpression.replace(rgx, binary[counter]);
          }

          counter++;
        });

        const flagTruthOperand =
          this.TruthtablePRV.calculatorInput.indexOf("1") > -1;
        const flagFalseOperand =
          this.TruthtablePRV.calculatorInput.indexOf("0") > -1;

        observer.next({
          binary: binary.join(""),
          prefixExpression: tempExpression,
          flagTruthOperand,
          flagFalseOperand
        });
      }

      observer.complete();
    });
  }

  public evaluatePrefixExpression(
    prefixExpression,
    index,
    flagTruthOperand,
    flagFalseOperand,
    operands,
  ): Observable<string[]> {
    return new Observable<string[]>(observer => {
      const stack = [];

      setTimeout(() => {
        for (let i = prefixExpression.length - 1; i >= 0; i--) {
          const element = prefixExpression[i];
          if (element === "0" || element === "1") {
            if (element === "0") {
              stack.push(false);
            } else {
              stack.push(true);
            }
          } else {
            let operandA = false;
            let operandB = false;

            if (element === "!") {
              operandA = stack[stack.length - 1];
              stack.pop();
            } else {
              operandB = stack[stack.length - 1];
              stack.pop();
              operandA = stack[stack.length - 1];
              stack.pop();
            }

            switch (element) {
              case "!":
                stack.push(this.not(operandA));

                break;
              case OperatorsToSymbols["∨"]:
                stack.push(this.or(operandA, operandB));
                break;
              case OperatorsToSymbols["∧"]:
                stack.push(this.and(operandA, operandB));
                break;
              case OperatorsToSymbols["⊽"]:
                stack.push(this.nor(operandA, operandB));
                break;
              case OperatorsToSymbols["⊼"]:
                stack.push(this.nand(operandA, operandB));
                break;
              case OperatorsToSymbols["↔"]:
                stack.push(this.equivalence(operandA, operandB));
                break;
              case OperatorsToSymbols["⊻"]:
                stack.push(this.xor(operandA, operandB));
                break;
              case OperatorsToSymbols["→"]:
                stack.push(this.implies(operandB, operandA));
                break;
              case OperatorsToSymbols["←"]:
                stack.push(this.implies(operandA, operandB));
                break;
              default:
                break;
            }
          }
        }

        const result = (
          (operands.size > 0 ? index : "") +
          (flagFalseOperand ? 0 : "") +
          (flagTruthOperand ? 1 : "") +
          Number(stack[0])
        ).split("");

        observer.next(result);

        observer.complete();
      }, 0);
    });
  }
}
