import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  /*{
    path: "normal-forms",
    loadChildren: () =>
      import("./modules/normal-forms/normal-forms.module").then(m => m.NormalFormsModule)
  },*/
  {
    path: "ausdrucksbaum",
    loadChildren: () =>
      import("./modules/tree/tree.module").then(m => m.TreeModule)
  },
  {
    path: "tabelle",
    loadChildren: () =>
      import("./modules/table/table.module").then(m => m.TableModule)
  },
  {
    path: "tabelle-mit-zwischenschritten",
    loadChildren: () =>
      import("./modules/table/table.module").then(m => m.TableModule)
  },
  {
    path: "knf-dnf",
    loadChildren: () =>
      import("./modules/normalforms/normalforms.module").then(m => m.NormalformsModule)
  },
  {
    path: "impressum",
    loadChildren: () =>
      import("./modules/imprint/imprint.module").then(m => m.ImprintModule)
  },
  {
    path: "",
    redirectTo: "tabelle",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "tabelle",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
