import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import {
  BooleanOperator,
  NamesToOperators,
  SymbolsToNames,
  OperatorsToNames,
  SymbolsToOperators
} from "../enums/boolean-operator.enum";
import { TruthtableProvider } from "../providers/truthtable.provider";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { ExpressionService } from "../services/expression.service";
import {
  take,
  filter,
  delay,
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { replaceXwithY, replaceXwithYAsync } from "../helper/replace.helper";
import { allowedInputs } from "./validators/allowed-inputs.validator";
import { parenthesesMatch } from "./validators/parentheses-match.validator";
import { notOperator } from "./validators/not-operator.validator";
import { operatorPosition } from "./validators/operator-position.validator";
import {
  trigger,
  state,
  style,
  animate,
  transition
  // ...
} from "@angular/animations";
import { operandPosition } from "./validators/operand-position.validator";

@Component({
  selector: "truthtable-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("flyInOut", [
      state("in", style({ opacity: 1, transform: "translateY(0)" })),
      transition("void => *", [
        style({
          opacity: 0,
          transform: "translateY(100%)"
        }),
        animate("0.2s ease-in")
      ]),
      transition("* => void", [
        animate(
          "0.2s 0.1s ease-out",
          style({
            opacity: 0,
            transform: "translateY(-100%)"
          })
        )
      ])
    ])
  ]
})
export class CalculatorComponent implements AfterViewInit {
  public BooleanOperator = BooleanOperator;

  public expression: FormControl = new FormControl("", [
    allowedInputs,
    parenthesesMatch,
    notOperator,
    operatorPosition,
    operandPosition
  ]);

  public expressionForm: FormGroup = new FormGroup({
    expression: this.expression
  });

  public cursorPosition = 0;

  public errors = [];

  @ViewChild("calculatorInput")
  calculatorInputRef: ElementRef;

  constructor(
    public TruthtablePRV: TruthtableProvider,
    private ExpressionSRV: ExpressionService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.TruthtablePRV.calculatorInput = "";
    this.onChange();

    this.router.events
      .pipe(
        filter((data: any) => !!data.state),
        take(1),
        delay(100)
      )
      .subscribe(data => {
        if (data instanceof RoutesRecognized) {
          const param = "formel";
          const value = replaceXwithY({
            value: data.state.root.queryParams[param],
            mapper: NamesToOperators,
            deleteThis: "-"
          });
          this.expression.setValue(value);
          this.TruthtablePRV.originalExpression = value;
        }
      });
  }

  public onClick(input: string) {
    const cursorPosition =
      this.calculatorInputRef.nativeElement.selectionStart ||
      this.expression.value.length;

    this.expression.setValue(
      this.expression.value.slice(0, cursorPosition) +
        input +
        this.expression.value.slice(
          cursorPosition,
          this.expression.value.length
        )
    );

    setTimeout(() => {
      this.calculatorInputRef.nativeElement.setSelectionRange(
        cursorPosition + 1,
        cursorPosition + 1
      );
      this.calculatorInputRef.nativeElement.focus();
    });
  }

  public onChange() {
    this.expression.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(expression =>
          replaceXwithYAsync({ value: expression, mapper: SymbolsToOperators })
        ),
        switchMap(expression =>
          replaceXwithYAsync({ value: expression, mapper: NamesToOperators })
        ),
        tap(e => this.expression.setValue(e.toLowerCase())),
        debounceTime(1000),
        tap(expression => {
          this.updateQueryParams(expression);
        }),
        tap(() =>
          this.getErrorList(this.expressionForm.controls.expression.errors)
        ),
        filter(() => this.expressionForm.valid),
        tap(expression => {
          this.TruthtablePRV.calculatorInput = expression.toLowerCase();
          this.TruthtablePRV.originalExpression = expression.toLowerCase();
          this.TruthtablePRV.calculatorInputChange.emit();
        })
      )
      .subscribe();
  }

  public onClear() {
    this.expression.setValue("");
    this.TruthtablePRV.calculatorInput = "";
    this.TruthtablePRV.calculatorInputChange.emit();
    this.TruthtablePRV.prefixExpression = [];
    this.TruthtablePRV.operands = new Set([]);
    this.updateQueryParams("");
  }

  private updateQueryParams(value) {
    // changes the route without moving from the current view or
    // triggering a navigation event,

    const routerTarget = {
      relativeTo: this.route,
      queryParams: undefined
    };

    const params = {
      queryParams: {
        formel: this.replaceSymbolsAndOperatorsWithNames(value)
      },
      queryParamsHandling: "merge"
      // preserve the existing query params in the route
    };

    if (!!params.queryParams.formel) {
      routerTarget.queryParams = params.queryParams;
    }

    this.router.navigate([], routerTarget);
  }

  private replaceSymbolsAndOperatorsWithNames(value) {
    value = replaceXwithY({ value, mapper: SymbolsToNames, hyphens: true });

    return replaceXwithY({
      value,
      mapper: OperatorsToNames,
      hyphens: true
    });
  }

  public getErrorList(errorObject) {
    if (!!errorObject) {
      const errors = Object.keys(errorObject).map(
        err => errorObject[err].message
      );
      this.errors = errors;
    } else {
      this.errors = [];
    }
  }
}
