<button
  class="relative m-1 border-4 rounded-lg w-14 h-14 sm:m-4 sm:w-20 sm:h-20 bg-hue-4-highlight border-hue-4-stroke hover:bg-hue-4-tertiary"
  [class]="extraClasses"
>
  <div class="relative flex flex-col w-full h-full">
    <div
      *ngIf="!!left && !!right"
      class="absolute flex flex-row items-start justify-between w-full h-full px-1 -mt-1 sm:px-2 sm:mt-0"
    >
      <div class="flex flex-row items-center justify-between w-full text-2xs sm:text-xs">
        <div>{{ left }}</div>
        <div class="text-sm">{{ right }}</div>
      </div>
    </div>
    <div
      class="flex items-center justify-center w-full h-full text-xl"
      [ngStyle]="!!left && !!right ? {'font-weight': '900', 'text-shadow': '1px 0 #000000'} : {}"
    >
      {{ main }}
    </div>
  </div>
</button>
