import { SymbolsToOperators } from "../enums/boolean-operator.enum";
import { Observable } from "rxjs";

function getCount(str, search) {
  return str.split(search).length - 1;
}

export function replaceXwithY(props: {
  value: string;
  mapper: object;
  hyphens?: boolean;
  deleteThis?: string;
}) {
  if (!!props.value) {
    Object.keys(props.mapper).forEach(key => {
      if (props.value.indexOf(key) > -1) {
        const input: any = document.getElementById("calculatorInput");
        const start = input.selectionStart;
        const end = input.selectionEnd;

        const textBefore = props.value.substr(0, end);
        const replaceWith = props.mapper[key];
        const lengthDiff =
          (replaceWith.length - key.length) * getCount(textBefore, key);

        let rgx: RegExp;
        if (!!SymbolsToOperators[key]) {
          rgx = new RegExp("\\" + key, "g");
        } else {
          rgx = new RegExp(key, "g");
        }

        if (props.hyphens) {
          const replaceThis =
            (key === "!" ? "" : "-") + props.mapper[key] + "-";
          props.value = props.value.replace(rgx, replaceThis);
        } else {
          props.value = props.value.replace(rgx, props.mapper[key]);

          setTimeout(() => {
            input.selectionStart = start + lengthDiff;
            input.selectionEnd = end + lengthDiff;
            input.focus();
          });
        }
      }
    });

    if (props.deleteThis) {
      const rgxDelete = new RegExp("\\" + props.deleteThis, "g");
      props.value = props.value.replace(rgxDelete, "");
    }

    const rgxParams = new RegExp("--", "g");
    props.value = props.value.replace(rgxParams, "-");
    return props.value;
  } else {
    return "";
  }
}

export function replaceXwithYAsync(props: {
  value: string;
  mapper: object;
  hyphens?: boolean;
  deleteThis?: string;
}): Observable<string> {
  return new Observable<string>(observer => {
    if (!!props.value) {
      Object.keys(props.mapper).forEach(key => {
        if (props.value.indexOf(key) > -1) {
          const input: any = document.getElementById("calculatorInput");
          const start = input.selectionStart;
          const end = input.selectionEnd;

          const textBefore = props.value.substr(0, end);
          const replaceWith = props.mapper[key];
          const lengthDiff =
            (replaceWith.length - key.length) * getCount(textBefore, key);

          let rgx: RegExp;
          if (!!SymbolsToOperators[key]) {
            rgx = new RegExp("\\" + key, "g");
          } else {
            rgx = new RegExp(key, "g");
          }

          if (props.hyphens) {
            const replaceThis =
              (key === "!" ? "" : "-") + props.mapper[key] + "-";
            props.value = props.value.replace(rgx, replaceThis);
          } else {
            props.value = props.value.replace(rgx, props.mapper[key]);

            setTimeout(() => {
              input.selectionStart = start + lengthDiff;
              input.selectionEnd = end + lengthDiff;
              input.focus();
            });
          }
        }
      });

      if (props.deleteThis) {
        const rgxDelete = new RegExp("\\" + props.deleteThis, "g");
        props.value = props.value.replace(rgxDelete, "");
      }

      const rgxParams = new RegExp("--", "g");
      props.value = props.value.replace(rgxParams, "-");
      observer.next(props.value);
      observer.complete();
    } else {
      observer.next("");
      observer.complete();
    }
  });
}
