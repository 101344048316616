import { FormControl } from "@angular/forms";

export function allowedInputs(c: FormControl) {
  // const rgx = new RegExp("[a-z01]\!\*\#\/\&\|\>\<\=∨⊽∧⊼↔⊻→←⊤⊥ ");
  const rgx = new RegExp("^[a-z01\!\*\#\/\&\|\>\<\=∨⊽∧⊼↔⊻→←⊤⊥¬\(\) ]*$");

  return rgx.test(c.value)
    ? null
    : {
        allowedInputs: {
          valid: false,
          message: "Fehler: Unbekanntes Zeichen",
        }
      };
}
