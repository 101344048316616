import { Component } from "@angular/core";


@Component({
  selector: "truthtable-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "Wahrheitstabelle";
}
