import { FormControl } from "@angular/forms";

export function operatorPosition(c: FormControl) {
  const error = {
    operatorPosition: {
      valid: false,
      message: "Unbekannter Fehler: Operatoren"
    }
  };

  const rgx = new RegExp("^[∨⊽∧⊼↔⊻→←]*$");

  if (rgx.test(c.value[0])) {
    error.operatorPosition.message =
      "Fehler: Operator steht an erster Position [ " +
      c.value[0] +
      " (Pos.: " +
      0 +
      ")]";
    return error;
  }

  if (rgx.test(c.value[c.value.length - 1])) {
    error.operatorPosition.message =
      "Fehler: Operator steht an letzter Position [ " +
      c.value[c.value.length - 1] +
      " (Pos.: " +
      (c.value.length - 1) +
      ")]";
    return error;
  }

  for (let i = 0; i < c.value.length - 1; i++) {
    // an operator should not follow another operator
    if (rgx.test(c.value[i]) && rgx.test(c.value[i + 1])) {
      error.operatorPosition.message =
        "Fehler: Operator folgt Operator [ " +
        c.value[i] +
        " (Pos.: " +
        i +
        ")]";
      return error;
    }

    // an operator should not follow an open parenthesis
    if (rgx.test(c.value[i]) && c.value[i - 1] === "(") {
      error.operatorPosition.message =
        "Fehler: Operator steht hinter offener Klammer [ " +
        c.value[i] +
        " (Pos.: " +
        i +
        ")]";
      return error;
    }

    // an operator should not placed before a closed parenthesis
    if (rgx.test(c.value[i]) && c.value[i + 1] === ")") {
      error.operatorPosition.message =
        "Fehler: Operator steht vor geschlossener Klammer [ " +
        c.value[i] +
        " (Pos.: " +
        i +
        ")]";
      return error;
    }
  }

  return true ? null : error;
}
