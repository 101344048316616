<div class="flex flex-col justify-between w-full h-full p-4 overflow-y-auto sm:p-8">
  <div class="flex flex-col w-full mb-4 sm:relative">
    <h2
      class="mb-1 ml-1 text-xl tracking-wider sm:text-2xl text-hue-4-headline"
    >
      Deine Formel
    </h2>
    <form [formGroup]="expressionForm">
      <input
        #calculatorInput
        id="calculatorInput"
        class="w-full h-16 p-4 text-lg tracking-widest border-4 rounded-lg bg-hue-4-main border-hue-4-stroke"
        type="text"
        formControlName="expression"
        [value]="expressionForm.value.expression"
        autofocus
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        placeholder="a∨b"
      />
    </form>
    <div
      *ngFor="let err of errors"
      [@flyInOut]="'in'"
      class="absolute bottom-0 z-10 flex items-center justify-center w-full h-10 px-2 mb-2 -ml-4 text-sm leading-none border-2 rounded-lg sm:leading-normal sm:text-xs bg-hue-4-warning border-hue-4-stroke sm:w-auto sm:top-0 sm:bottom-auto sm:right-0 sm:h-auto sm:mt-2"
    >
      <div>{{ err }}</div>
    </div>
    <div
      *ngIf="!TruthtablePRV.ready"
      [@flyInOut]="'in'"
      class="absolute bottom-0 z-20 flex items-center justify-center w-full h-10 px-2 mb-2 -ml-4 text-sm leading-none bg-yellow-600 border-2 rounded-lg sm:leading-normal sm:text-xs border-hue-4-stroke sm:w-auto sm:top-0 sm:bottom-auto sm:right-0 sm:h-auto sm:-mt-6"
    ><div>Warnung: Rechenintensive Operation... <br /> Warte mit deiner nächsten Eingabe.<br /> Sie wird sonst nicht berücksichtigt.</div></div>
  </div>

  <div class="flex flex-col justify-start w-full">
    <h2
      class="mb-4 ml-1 text-xl tracking-wider sm:text-2xl text-hue-4-headline"
    >
      Eingabe
    </h2>
    <div class="flex flex-row justify-center -mt-4 xl:justify-start xl:mt-0">
      <div class="flex flex-row justify-start mr-1 sm:mr-8">
        <truthtable-button
          [main]="'a'"
          [extraClasses]="'sm:ml-0'"
          (click)="onClick('a')"
        ></truthtable-button>
        <truthtable-button
          [main]="'b'"
          (click)="onClick('b')"
        ></truthtable-button>
        <truthtable-button
          [main]="'c'"
          (click)="onClick('c')"
        ></truthtable-button>
      </div>
      <div class="flex flex-row justify-end">
        <truthtable-button
          [main]="BooleanOperator.OR"
          [left]="'or'"
          [right]="'|'"
          (click)="onClick('|')"
        ></truthtable-button>

        <truthtable-button
          [main]="BooleanOperator.NOR"
          [left]="'nor'"
          [right]="'/'"
          (click)="onClick('/')"
        ></truthtable-button>
      </div>
    </div>
    <div class="flex flex-row justify-center xl:justify-start">
      <div class="flex flex-row justify-start mr-1 sm:mr-8">
        <truthtable-button
          [main]="'d'"
          [extraClasses]="'sm:ml-0'"
          (click)="onClick('d')"
        ></truthtable-button>
        <truthtable-button
          [main]="'e'"
          (click)="onClick('e')"
        ></truthtable-button>
        <truthtable-button
          [main]="'f'"
          (click)="onClick('f')"
        ></truthtable-button>
      </div>
      <div class="flex flex-row justify-end">
        <truthtable-button
          [main]="BooleanOperator.AND"
          [left]="'and'"
          [right]="'&'"
          (click)="onClick('&')"
        ></truthtable-button>

        <truthtable-button
          [main]="BooleanOperator.NAND"
          [left]="'nand'"
          [right]="'#'"
          (click)="onClick('#')"
        ></truthtable-button>
      </div>
    </div>
    <div class="flex flex-row justify-center xl:justify-start sm:mb-8">
      <div class="flex flex-row justify-start mr-1 sm:mr-8">
        <truthtable-button
          [main]="'('"
          [extraClasses]="'sm:ml-0'"
          (click)="onClick('(')"
        ></truthtable-button>
        <truthtable-button
          [main]="BooleanOperator.NOT"
          [left]="'not'"
          [right]="'!'"
          (click)="onClick('!')"
        ></truthtable-button>
        <truthtable-button
          [main]="')'"
          (click)="onClick(')')"
        ></truthtable-button>
      </div>
      <div class="flex flex-row justify-end">
        <truthtable-button
          [main]="BooleanOperator.EQUIVALENCE"
          [left]="'equ'"
          [right]="'='"
          (click)="onClick('=')"
        ></truthtable-button>
        <truthtable-button
          [main]="BooleanOperator.XOR"
          [left]="'xor'"
          [right]="'*'"
          (click)="onClick('*')"
        ></truthtable-button>
      </div>
    </div>
    <div class="flex flex-row justify-center xl:justify-start">
      <div class="flex flex-row justify-start mr-1 sm:mr-8">
        <truthtable-button
          [main]="BooleanOperator.TAUTOLOGY"
          [left]="'true'"
          [right]="'1'"
          [extraClasses]="'sm:ml-0'"
          (click)="onClick('1')"
        ></truthtable-button>
        <truthtable-button
          [main]="BooleanOperator.CONTRADICTION"
          [left]="'false'"
          [right]="'0'"
          (click)="onClick('0')"
        ></truthtable-button>
        <truthtable-button [main]="'C'" (click)="onClear()"></truthtable-button>
      </div>
      <div class="flex flex-row justify-end">
        <truthtable-button
          [main]="BooleanOperator.IMPLICATION_LR"
          [left]="'imp'"
          [right]="'>'"
          (click)="onClick('>')"
        ></truthtable-button>
        <truthtable-button
          [main]="BooleanOperator.IMPLICATION_RL"
          [left]="'pmi'"
          [right]="'<'"
          (click)="onClick('<')"
        ></truthtable-button>
      </div>
    </div>
  </div>

  <div class="flex flex-col items-start justify-center w-full">
    <h1
      class="mt-4 mb-4 ml-1 text-xl tracking-wider sm:text-2xl text-hue-4-headline"
    >
      Darstellung des Ergebnisses
    </h1>
    <div class="grid grid-cols-2 sm:grid-cols-4 w-full gap-2 sm:gap-8">
      <button
        class="w-full h-20 text-xs border-4 rounded-lg border-hue-4-stroke focus:bg-hue-4-tertiary sm:text-base"
        routerLink="/tabelle"
        routerLinkActive="bg-hue-4-tertiary"
        queryParamsHandling="merge"
      >
        Tabelle
      </button>
      <button
        class="w-full h-20 text-xs border-4 rounded-lg border-hue-4-stroke focus:bg-hue-4-tertiary sm:text-base"
        routerLink="/tabelle-mit-zwischenschritten"
        routerLinkActive="bg-hue-4-tertiary"
        queryParamsHandling="merge"
      >
        Tabelle mit<br />
        Schritten
      </button>
      <button
        class="w-full h-20 text-xs border-4 rounded-lg border-hue-4-stroke focus:bg-hue-4-tertiary sm:text-base"
        routerLink="/ausdrucksbaum"
        routerLinkActive="bg-hue-4-tertiary"
        queryParamsHandling="merge"
      >
        Baum
      </button>
      <button
        class="relative w-full h-20 text-xs border-4 rounded-lg border-hue-4-stroke focus:bg-hue-4-tertiary sm:text-base"
        routerLink="/knf-dnf"
        routerLinkActive="bg-hue-4-tertiary"
        queryParamsHandling="merge"
      >
        KNF / DNF
        <span class="absolute -top-4 -right-4 p-1 rounded-lg border-4 border-hue-4-stroke bg-yellow-500">Beta</span>
      </button>
    </div>
  </div>
</div>
