<div class="h-full">
  <div class="flex items-center justify-between w-full h-12 bg-hue-4-bg">
    <h1
      class="px-1 ml-4 text-base tracking-widest whitespace-no-wrap shadow-inner cursor-pointer sm:text-2xl sm:ml-8 text-hue-4-headline"
      routerLink="/tabelle"
    >
      Wahrheitstabelle
    </h1>
    <div class="flex flex-row justify-end">
      <div
        class="flex flex-row items-center px-1 mr-0 text-sm sm:mr-8 text-hue-4-btn-text"
      >
        <a routerLink="/impressum" class="flex flex-row">
          <img
            src="assets/gavel.png"
            class="w-5 h-5 min-w-0 p-1 mr-2 rounded-sm bg-twitter"
            style="min-width: 21px;"
          />
          <span class="hidden sm:block">Impressum</span>
        </a>
      </div>
      <div
        class="flex flex-row items-center px-1 mr-0 text-sm sm:mr-8 text-hue-4-btn-text"
      >
        <a
          href="https://twitter.com/joerndigital"
          target="_blank"
          class="flex flex-row"
        >
          <img
            src="assets/twitter.png"
            class="w-5 h-5 min-w-0 mr-2"
            style="min-width: 21px;"
          />
          <span class="hidden sm:block">Feedback</span></a
        >
      </div>
      <div
        class="flex flex-row items-center px-1 mr-0 text-sm sm:mr-8 text-hue-4-btn-text"
      >
        <a
          href="https://www.paypal.me/daug"
          target="_blank"
          class="flex flex-row"
        >
          <img
            src="assets/coffee.png"
            class="w-5 h-5 min-w-0 p-1 mr-2 rounded-sm bg-twitter"
            style="min-width: 21px;"
          />
          <span class="hidden sm:block">Kaffee</span>
        </a>
      </div>
    </div>
  </div>

  <div
    class="flex flex-col w-full lg:flex-col xl:flex-row app__container"
  >
    <div class="w-full lg:w-full xl:w-auto bg-hue-4-paragraph app__calculator">
      <truthtable-calculator></truthtable-calculator>
    </div>
    <div
      class="flex flex-col w-full xl:overflow-y-auto bg-hue-4-main lg:w-full app__result overlay"
    >
      <router-outlet></router-outlet>
      <footer class="pb-2 text-sm text-center lg:text-left">
          <div xmlns:cc="http://creativecommons.org/ns#" class="flex flex-col self-end w-full gap-1 px-2 pb-0 font-sans text-right sm:px-8">
            <div>
              <a class="font-sans" rel="cc:attributionURL" href="http://wahrheitstabelle.daug.de"
              >Die Seite daug.de/wahrheitstabelle </a
            >
            ist lizensiert unter&nbsp;
            </div>
            <a
              href="http://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1"
              target="_blank"
              rel="license noopener noreferrer"
              class="flex flex-row justify-end"
              >CC BY-SA 4.0<img
                style="
                  height: 22px !important;
                  margin-left: 3px;
                  vertical-align: text-bottom;
                "
                src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" /><img
                style="
                  height: 22px !important;
                  margin-left: 3px;
                  vertical-align: text-bottom;
                "
                src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" /><img
                style="
                  height: 22px !important;
                  margin-left: 3px;
                  vertical-align: text-bottom;
                "
                src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"
            /></a>
          </div>
      </footer>
    </div>
  </div>
</div>

